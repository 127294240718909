import { statusLabels } from '@enums/statusEnum';
import { FaCircleCheck, FaClock, FaCircleXmark } from 'react-icons/fa6';
import styles from './TagStatus.module.scss';

type StatusType = {
  operationStatus: 'pending' | 'completed' | 'error';
};

export const TagStatus = (props: { status: StatusType }) => {
  const { status } = props;
  console.log('mar', status);
  const statusLabel = statusLabels.find(
    (e) => status.operationStatus === e.code
  );

  const iconMap = {
    pending: <FaClock size={16} color="#f78310" />,
    completed: <FaCircleCheck size={16} color="#02b920" />,
    error: <FaCircleXmark size={14} color="#fa2a2a" />,
  };

  console.log('statusLabel', statusLabel);
  return (
    <div
      className={`${styles.statusTypeLabel} 
      ${styles[`${statusLabel?.color}Border` || 'black']}`}
    >
      {iconMap[status.operationStatus] || (
        <i className={`${styles.icon} ${styles.black}`} />
      )}
      <span className={styles[statusLabel?.color || 'black']}>
        {[statusLabel?.name]}
      </span>
    </div>
  );
};
