import { isAxiosError } from 'axios';

import { axiosFuex } from '@httpClients';
import { ListPages } from '@enums/enums';
import { B2cAuthErrorTypes } from '@enums/B2cAuthErrorTypesEnum';

import { CreateDisbursementResponseType } from '../types/responses/create-disbursement';
import { DisbursementType } from '../types/DisbursementType';

export const createDisbursement = async (
  body: DisbursementType
): Promise<CreateDisbursementResponseType> => {
  try {
    const path = '/disbursement/single';
    const raw = JSON.stringify(body);
    const authToken = sessionStorage.getItem('authToken');
    const authHeader = `Bearer ${authToken}`;

    const {
      data: { errors, status_code: status, data },
    } = await axiosFuex.post(path, raw, {
      headers: {
        Authorization: authHeader,
      },
    });
    if (errors) throw new Error(errors);
    if (status !== 200) throw new Error('Error creating disbursement');

    const response: CreateDisbursementResponseType = data;

    return response;
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response?.status === 401 || error.response?.status === 403) {
        window.location.href = `${ListPages.Login}?error=${B2cAuthErrorTypes.MissingToken}`;
        throw new Error('Unauthorized access, redirecting to login.');
      } else {
        throw new Error(error.message);
      }
    } else {
      throw error;
    }
  }
};
