import React, { useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import {
  FaHouse,
  FaPeopleGroup,
  FaArrowRightArrowLeft,
  FaFile,
  FaDollarSign,
  FaGear,
  FaArrowUpRightFromSquare,
  FaPlus,
} from 'react-icons/fa6';
import { Button } from 'primereact/button';
import { ConfirmationModal } from '@components/ConfirmationModal/ConfirmationModal';
import { ListPages } from '@enums/enums';
import { msalInstance } from '../../msalconfig';
import styles from './SideBar.module.scss';

interface MenuItemProps {
  icon: React.ComponentType<{ className?: string }>;
  label: string;
  link?: string;
  pathname?: string;
}

interface MenuSectionProps {
  title: string;
  items: MenuItemProps[];
  pathname?: string;
}

const mainMenuItems: MenuItemProps[] = [
  { icon: FaHouse, label: 'Dashboard' },
  { icon: FaPeopleGroup, label: 'Recipients' },
  {
    icon: FaArrowRightArrowLeft,
    label: 'Transactions',
    link: ListPages.DashboardReports,
  },
  {
    icon: FaPlus,
    label: 'New disbursement',
    link: ListPages.Disbursement,
  },
  { icon: FaFile, label: 'Reports' },
];

const toolsMenuItems: MenuItemProps[] = [
  { icon: FaDollarSign, label: 'Payments' },
  { icon: FaGear, label: 'Settings' },
];

const MenuItem: React.FC<MenuItemProps> = ({
  icon: Icon,
  label,
  link = '',
  pathname = '',
}) => {
  const isSelected = link && pathname === link;
  const content = (
    <li
      className={`${isSelected ? styles.selectedSidebarTab : ''} ${
        styles.sidebarItem
      }`}
    >
      <Icon className={styles.icons} />
      <span>{label}</span>
    </li>
  );

  return link ? <Link to={link}>{content}</Link> : content;
};

MenuItem.defaultProps = {
  link: '',
  pathname: '',
};

const MenuSection: React.FC<MenuSectionProps> = ({
  title,
  items,
  pathname = '',
}) => (
  <div className={styles.sidebarMain}>
    <h4 className={styles.sidebarSectionTitle}>{title}</h4>
    <ul className={styles.sidebarTools}>
      {items.map((item) => (
        <MenuItem
          icon={item.icon}
          label={item.label}
          link={item.link}
          pathname={pathname}
          key={item.label}
        />
      ))}
    </ul>
  </div>
);

MenuSection.defaultProps = {
  pathname: '',
};

const SideBar: React.FC = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const { pathname } = useLocation();

  const handleLogout = async () => {
    sessionStorage.removeItem('authToken');
    await msalInstance.logoutRedirect({
      postLogoutRedirectUri: window.location.origin,
    });
    navigate(ListPages.Login, { replace: true });
  };

  return (
    <div>
      <div className={styles.sidebarContainer}>
        <div className={styles.sidebar}>
          <div className={styles.sidebarNavigation}>
            <MenuSection
              title="MAIN MENU"
              items={mainMenuItems}
              pathname={pathname}
            />
            <MenuSection title="TOOLS" items={toolsMenuItems} />
          </div>
          <div className={styles.sidebarLogout}>
            <Button
              label="Logout"
              icon={<FaArrowUpRightFromSquare className={styles.icons} />}
              className={styles.sidebarLogoutLink}
              onClick={() => setShowModal(true)}
            />
          </div>
        </div>
      </div>
      <ConfirmationModal
        visible={showModal}
        onHide={() => setShowModal(false)}
        onConfirm={handleLogout}
        headerText="logoutModal.headerText"
        contentText="logoutModal.contentText"
        confirmText="logoutModal.confirmText"
        cancelText="logoutModal.cancelText"
      />
    </div>
  );
};

export default SideBar;
