import { RouterType } from '@interfaces/interfaces';
import Login from '../pages/Login/Login';
import Logout from '../pages/Logout/Logout';
import ForgotPassword from '../pages/ForgotPassword/ForgotPassword/ForgotPassword';
import NotFound from '../pages/NotFound/NotFound';
import { ListPages } from '../enums/enums';
import Home from '../pages/Home';
import Landing from '../pages/Landing';
import MyProfile from '../pages/MyProfile/MyProfile';
import DashboardReports from '../pages/DashboardReports/DashboardReports';
import B2cRedirect from '../pages/B2cRedirect/B2cRedirect';
import Disbursement from '../pages/Disbursement/Disbursement';
import NewDisbursementSingle from '../pages/NewDisbursementSingle/NewDisbursementSingle';
import DisbursementProcessed from '../pages/DisbursementProcessed/DisbursementProcessed';
import DisbursementSuccess from '../pages/DisbursementSuccess/DisbursementSuccess';
import DisbursementError from '../pages/DisbursementError/DisbursementError';

export const pagesAuth: RouterType[] = [
  {
    path: ListPages.DashboardReports,
    element: <DashboardReports />,
  },
  {
    path: ListPages.Disbursement,
    element: <Disbursement />,
  },
  {
    path: ListPages.NewDisbursement,
    element: <NewDisbursementSingle />,
  },
  {
    path: ListPages.DisbursementProcessed,
    element: <DisbursementProcessed />,
  },
  {
    path: ListPages.DisbursementSuccess,
    element: <DisbursementSuccess />,
  },
  {
    path: ListPages.DisbursementError,
    element: <DisbursementError />,
  },

  {
    path: ListPages.Login,
    element: <Login />,
  },
  {
    path: ListPages.Logout,
    element: <Logout />,
  },
  {
    path: ListPages.Forgot,
    element: <ForgotPassword />,
  },
  {
    path: ListPages.ChangePass,
    element: <MyProfile opt="change-password" />,
  },
  {
    path: ListPages.NotFound,
    element: <NotFound />,
  },
  {
    path: ListPages.Home,
    element: <Home />,
  },
  {
    path: ListPages.Landing,
    element: <Landing />,
  },
  {
    path: ListPages.B2cRedirect,
    element: <B2cRedirect />,
  },
];
