export const statusLabels = [
  {
    code: 'pending',
    name: 'Pending',
    icon: 'pi pi-clock',
    color: 'orange',
  },
  {
    code: 'completed',
    name: 'Approved',
    icon: 'pi pi-check',
    color: 'green',
  },
  {
    code: 'error',
    name: 'Rejected',
    icon: 'pi pi-times',
    color: 'red',
  },
];
