import React, { createContext, useContext, useState, useMemo } from 'react';

interface DisbursementData {
  dueDate: string;
  currency: string;
  amount: string;
  cardNumber: string;
  cardNumberView: string;
  concept: string;
  email: string;
  setDueDate: React.Dispatch<React.SetStateAction<string>>;
  setCurrency: React.Dispatch<React.SetStateAction<string>>;
  setAmount: React.Dispatch<React.SetStateAction<string>>;
  setCardNumber: React.Dispatch<React.SetStateAction<string>>;
  setCardNumberView: React.Dispatch<React.SetStateAction<string>>;
  setConcept: React.Dispatch<React.SetStateAction<string>>;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
}

const DisbursementContext = createContext<DisbursementData | undefined>(
  undefined
);

interface DisbursementProviderProps {
  children: React.ReactNode;
}
export const DisbursementProvider: React.FC<DisbursementProviderProps> = ({
  children,
}) => {
  const [dueDate, setDueDate] = useState<string>('');
  const [currency, setCurrency] = useState<string>('USD');
  const [amount, setAmount] = useState<string>('');
  const [cardNumber, setCardNumber] = useState<string>('');
  const [cardNumberView, setCardNumberView] = useState<string>('');
  const [concept, setConcept] = useState<string>('');
  const [email, setEmail] = useState<string>('');

  const contextValue = useMemo(
    () => ({
      dueDate,
      setDueDate,
      currency,
      setCurrency,
      amount,
      setAmount,
      cardNumber,
      setCardNumber,
      cardNumberView,
      setCardNumberView,
      concept,
      setConcept,
      email,
      setEmail,
    }),
    [dueDate, currency, amount, cardNumber, concept, cardNumberView, email]
  );

  return (
    <DisbursementContext.Provider value={contextValue}>
      {children}
    </DisbursementContext.Provider>
  );
};

export const useDisbursement = () => {
  const context = useContext(DisbursementContext);
  if (!context) {
    throw new Error(
      'useDisbursement must be used within a DisbursementProvider'
    );
  }
  return context;
};
