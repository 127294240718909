import React from 'react';
import { Button } from 'primereact/button';
import { ListPages } from '@enums/enums';
import { Link } from 'react-router-dom';
import styled from './Status.module.scss';

const Status: React.FC = () => {
  return (
    <div className={styled.statusContainer}>
      <Link to={ListPages.DisbursementError}>
        <Button
          className="p-button-danger p-mr-2"
          onClick={() => console.log('Error!')}
        >
          Ver caso de Error
        </Button>
      </Link>
    </div>
  );
};

export default Status;
