export enum ActionTypes {
  SetAToken = 'setAToken',
  SetForgotPasswordFlow = 'setForgotPasswordFlow',
  SetPasswordChanged = 'setPasswordChanged',
  SetStep = 'setStep',
  SetLogin = 'setLogin',
  SetChangePass = 'setChangePass',
  SetVerificationCode = 'setVerificationCode',
  SendUser = 'sendUser',
  SendCode = 'sendCode',
  ResetErrors = 'resetErrors',
}

export enum ListPages {
  Login = '/',
  Logout = '/logout',
  B2cRedirect = '/b2c-redirect',
  Forgot = '/forgot',
  ChangePass = '/change-password',
  Home = '/home',
  Landing = '/landing',
  DashboardReports = '/transactions-history',
  Disbursement = '/new-disbursement',
  NewDisbursement = '/disbursement',
  DisbursementProcessed = '/disbursement-processed',
  DisbursementSuccess = '/disbursement-success',
  DisbursementError = '/disbursement-error',
  DisbursementPending = '/disbursement-pending',
  NotFound = '*',
}

export enum ScenariosEnum {
  Pull = 'scenario-one',
  Push = 'scenario-two',
  Reverse = 'scenario-three',
  AdjustmentReverse = 'scenario-four',
  cardValidation = 'scenario-five',
  fundsTransfersAttributesInquiry = 'scenario-six',
  reportingFundsTransactions = 'scenario-seven',
  transactionQuery = 'scenario-eight',
  reports = 'scenario-nine',
  foreignExchange = 'scenario-ten',
  multiPull = 'scenario-eleven',
}

export enum MessageSeverityEnum {
  Secondary = 'secondary',
  Success = 'success',
  Info = 'info',
  Warning = 'warning',
  Help = 'help',
  Danger = 'danger',
}

export enum ColumnHeaders {
  transactionId = 'transactionId',
  cardNumber = 'cardNumber',
  merchantDate = 'merchantDate',
  authDate = 'authDate',
  transactionType = 'transactionType',
  operationStatus = 'operationStatus',
  amount = 'amount',
}

export enum SortEnum {
  descending = 'desc',
  ascending = 'asc',
}

export enum ProcessEnvEnum {
  ForgotPasswordUrl = 'REACT_APP_B2C_FORGOT_PASSW_URL',
}
