import logoFile from '../../assets/Logo.svg';
import styles from './Logo.module.scss';

export const Logo = () => {
  const logoPath = logoFile as unknown as string;

  return (
    <img
      alt="zenus bank logo"
      src={logoPath}
      className={styles.Zenus_Bank_Turquoise_Primary_RGB}
    />
  );
};
